import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import Logo from "../img/logo.png";
import { NavLink } from './NavLink';

const Navbar = () => {
  const { currentUser, logout } = useContext(AuthContext);

  return (
     <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
            <img src={Logo} height="50" className="d-inline-block align-top" alt=""/>
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {currentUser ? (<ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink href="/" exact className="nav-link">Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink href="/inventory" className="nav-item nav-link">Inventory</NavLink>
            </li>
            <li className="nav-item">
              <NavLink href="/reports" className="nav-item nav-link">Reports</NavLink>
            </li>
            <li className="nav-item">
              <NavLink href="/master/users" className="nav-item nav-link">Users</NavLink>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Preferences
              </a>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="#">{currentUser?.UserName.charAt(0).toUpperCase() + currentUser?.UserName.toLowerCase().slice(1)} Profile</a></li>
                <li><a className="dropdown-item" href="#">Change Password</a></li>
                <li><hr className="dropdown-divider"/></li>
              </ul>
            </li>
          </ul>) : (<ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>)}
          <form className="d-flex" role="search">
              {currentUser ? (
              <><input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
              <button className="btn btn-outline-success" type="submit">Search</button></>
              ) : <span/>}
              {currentUser ? (<button className="btn btn-outline-secondary" style={{marginLeft: '10px'}} type="button" onClick={logout}>Logout</button>) : (<Link className="btn btn-outline-secondary" style={{marginLeft: '10px'}} to="/login">Login</Link>)}

          </form>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
