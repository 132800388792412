import { helper } from '../helpers';

import { Link } from "react-router-dom";

export { CompanyLink };

function CompanyLink() {
    return (
            <h5 className="float-end"><Link to={"/modules?c=" + helper.getSessCompanyId()} className="text-decoration-none text-italic">{helper.getSessCompanyName()}</Link></h5>
    );
}