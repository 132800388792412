import React from "react";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import qs from 'query-string'
import pluralize from 'pluralize';

import { helper } from '../helpers'
import { Listview } from '../components/Listview';
import { CompanyLink } from '../components/CompanyLink'
import { Spinner } from '../components/Spinner'

const Transaction = () => {
  //axios = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const location = useLocation();
  const navigate = useNavigate();

  const moduleName = location.pathname.split("/")[2];
  const moduleApi = pluralize.singular(moduleName);

  const [records, setRecords] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchFilter, setSearchFilter] = useState('');

  const [config, setConfig] = useState({});

  function refreshPage() {
      //window.location.reload(false);
      navigate(`/transactionlist/` + pluralize.plural(moduleName))
  }

  function onViewButtonClick (e, row, conf) {
    console.log('onViewButtonClick', conf['header']['pk'])
      let id = row[conf['header']['pk']];
      console.log(moduleApi, id)
      navigate(`/transaction/${moduleName}/${id}`);   
  }

  async function handleSearch() {
      console.log('search', searchFilter)
      setLoading(true)

      var sFilter = searchFilter ? searchFilter.replace(/ /g,"%") : searchFilter;
      
      const res = await axios.get(`/transaction/${moduleApi}?q=${sFilter}&c=${helper.getSessCompanyId()}`);
      const data = res.data;
      setRecords(data)
      
      setLoading(false)
  }

  function handleChange(e) {
     setSearchFilter(e.target.value)
  }

  async function getConfig() {

    const res2 = await axios.get(`/config/${moduleApi}`);

    setConfig(res2.data);
    
    return res2.data;
  }

  useEffect(() => {
    const fetchData = () => {
        
        return new Promise (async (resolve, reject) => {

            setLoading(true);
            const conf = await getConfig();

            const res = await axios.get(`/transaction/${moduleApi}?c=${helper.getSessCompanyId()}`);
            const data = res.data;
            
            //setRecords(data);
            resolve(data);

            var line1 = data.length > 0 && data[0];

            //get columns
            var cols = Object.keys(line1).map((y, i) => {

                var hidden = false;

                if (i > 12) hidden = true;

                if (['Description', 'CustomerName', 'SupplierName', 'LastName', 'COA_DESCRIPTION'].includes(y)) {
                    return {field: y, headerName: y, width: 300, hide: hidden}
                }

                return {field: y, headerName: y, hide: hidden}
            });

            cols.unshift({ field: 'actions', headerName: 'Actions', width: 80, filterable: false, renderCell: (params) => {
                return (
                  <button
                    onClick={(e) => onViewButtonClick(e, params.row, conf)}
                    className="btn btn-sm btn-secondary"
                    variant="contained"
                  >
                    view
                  </button>
                );
              }})

            setColumns(cols); 
            setLoading(false);
        });
    };
    
    fetchData()
      .then((result) => {   
        setRecords(result);
      })
      .catch((error) => {
        //setError(error.message);
      });
  }, []);
 

  return (
    <div className="container">
        <CompanyLink/>
        <h3>{config && config.header && helper.toTitleCase(config['header']['label'])}</h3>
        <div className="row pt-2">
            <div className="col-md-8">
                <label className="m-2">Quick Search</label>
                <input id="outlined-search" label="Search Product" type="text" className="m-1" onChange={handleChange} 
                    onKeyPress={event => {
                        if (event.key === 'Enter') {
                          handleSearch()
                        }
                    }}
                />
                <button type="button" className="btn btn-sm btn-primary mb-1" onClick={handleSearch}>Search</button>
            </div>
            <div className="col-md-4">
                {/*<Link href="/products/add" className="btn btn-sm btn-primary">Add</Link>*/}
                <button type="button" className="btn btn-sm btn-primary margin-btn float-end" onClick={refreshPage}>Refresh</button>
                <Link to={"/modules?c=" + helper.getSessCompanyId()} className="btn btn-sm btn-primary margin-btn float-end">Back</Link>
                <Link to={"/transaction/" + moduleName + '?action=new'} className="btn btn-sm btn-primary margin-btn float-end">New</Link>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>{loading ? <Spinner/> : <Listview rows={records} columns={columns} hasRowId={true} />}</p>
            </div>
        </div>
    </div>
  );
};

export default Transaction;
