import { useState, useEffect } from 'react';
import qs from 'query-string'
import axios from "axios";
import { Link, useLocation } from "react-router-dom";

export default Module;

function Module() {
    //axios = axios.create({ baseURL: process.env.REACT_APP_API_URL });
    const [company, setCompany] = useState({});
    const [company_nick_name, setCompanyNick] = useState('');
    const modules = [
            {name: 'Master Data', cls: 'bg-danger',
                    modules: [{title: 'Products', link: '/masterlist/products'},
                             {title: 'Customers', link: '/masterlist/customers'}, 
                             {title: 'Suppliers', link: '/masterlist/suppliers'}, 
                             {title: 'Employees', link: '/masterlist/employees'}, 
                             {title: 'Chart of Accounts', link: '/masterlist/chartofaccounts'}, 
                             ]
            },
            {name: 'Sales & Receivables', cls: 'bg-primary',
                    modules: [{title: 'Quotation', link: '/transactionlist/quotations'},
                             {title: 'Sales Order', link: '/transactionlist/salesorders'},
                             {title: 'Delivery Out', link: '/transactionlist/drouts'},
                             {title: 'Credit Memo', link: '/transactionlist/creditmemos'},
                             {title: 'Sales Invoice', link: '/transactionlist/salesinvoices'},
                             {title: 'SOP', link: '/transactionlist/sops'},
                             {title: 'PDC', link: '/transactionlist/pdcs'},
                             {title: 'Official Receipt', link: '/transactionlist/officialreceipts'},
                             {title: 'Counter Receipt', link: '/transactionlist/counterreceipts'},
                             {title: 'Offset', link: '/transactionlist/offsets'},
                             {title: 'Customer Consignment', link: '/transactionlist/customerconsignments'}
                             ]
            },
            {name: 'Purchase & Payables', cls: 'bg-success',
                    modules: [{title: 'Purchase Request', link: '/transactionlist/purchaserequests'},
                             {title: 'Purchase Order', link: '/transactionlist/purchaseorders'},
                             {title: 'Delivery In', link: '/transactionlist/drins'},
                             {title: 'Debit Memo', link: '/transactionlist/debitmemos'},
                             {title: 'Billing Voucher', link: '/transactionlist/billingvouchers'},
                             {title: 'Check Vouchers', link: '/transactionlist/checkvouchers'},
                             {title: 'Deposits', link: '/transactionlist/deposits'},
                             {title: 'Supplier Consignment', link: '/transactionlist/supplierconsignments'},
                             {title: 'Repairs and Maintenance', link: '/transactionlist/repairs'}
                             ]
            },
            {name: 'Inventory', cls: 'bg-warning',
                    modules: [{title: 'Inventory Adjustment', link: '/transactionlist/inventoryadjustments'},
                             {title: 'Production Input', link: '/transactionlist/productioninputs'},
                             {title: 'Production Output', link: '/transactionlist/productionoutputs'},
                             {title: 'Stock Transfer', link: '/transactionlist/stocktransfers'},
                             {title: 'Stock Transfer Receipt', link: '/transactionlist/stocktransferreceipts'}
                             ]
            },
            {name: 'POS', cls: 'bg-info',
                    modules: [{title: 'Point of Sale (POS)', link: '/transactionlist/pos'}, 
                             {title: 'Suki Card', link: '/transactionlist/sukicards'},
                             {title: 'Pre-Order Item', link: '/transactionlist/preorderitems'}
                             ]
            },
            {name: 'HR & Payroll', cls: 'bg-secondary',
                    modules: [{title: 'Payroll Setup', link: '/transactionlist/payrollsetups'},
                             {title: 'DTR', link: '/transactionlist/dtrs'},
                             {title: 'Payroll Computation', link: '/transactionlist/payrollcomputations'},
                             {title: 'Bank Paylink', link: '/transactionlist/bankpaylinks'}
                             ]
            },
            {name: 'Financial', cls: 'bg-secondary',
                    modules: [{title: 'General Ledger', link: '/transactionlist/generalledgers'}, 
                             //{title: 'Fixed Assets', link: '/transactionlist/fixedassets'},
                             {title: 'Trial Balance', link: '/transactionlist/trialbalances'},
                             {title: 'Profit and Loss', link: '/transactionlist/profitandlossess'},
                             {title: 'Balance Sheet', link: '/transactionlist/balancesheets'},
                             {title: 'Cash In', link: '/transactionlist/cashins'},
                             {title: 'Cash Out', link: '/transactionlist/cashouts'}
                             ]
            },
            {name: 'Reports & Inquiries', cls: 'bg-dark',
                    modules: [{title: 'A/R Reports', link: '/reports/ar'}, 
                             {title: 'A/P Reports', link: '/reports/ap'},
                             {title: 'Inventory Reports', link: '/reports/inventory'},
                             {title: 'Payroll Reports', link: '/reports/payroll'},
                             {title: 'POS Reports', link: '/reports/pos'},
                             {title: 'Management Reports', link: '/reports/management'}
                             ]
            }
        ]

    useEffect(() => {
        
        const fetchData = async () => {
            try {
               const value=qs.parse(document.location.search);
               const cid = value.c;
          
                const res = await axios.get(`/master/company/` + cid, {withCredentials: true});
                var x = res.data;
              
                setCompany(x)

                var company_nickname = x.COMPANY_NICKNAME;//helperService.toTitleCase(x.COMPANY_NICKNAME);

                setCompanyNick(company_nickname);
                
                sessionStorage.setItem('onlinebgc:cid', cid);
                sessionStorage.setItem('onlinebgc:cname', company_nickname);
            
            } catch (err) {
              console.log(err);
            }
          };

          fetchData();
    }, []);


    return (
        <div className="container">
            <div className="row justify-content-right mt-2">
                <div className="col-md-12">
                    <h5 className="float-end"><Link href={"/modules?c=" + company.CompanyID} className="text-decoration-none text-italic">{company_nick_name}</Link></h5>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    {modules.map((m,i) => 
                    <div className="card" key={i}>
                      <div className="card-header">
                        <h5>{m.name}</h5>
                      </div>
                      <div className="card-body">
                            {m.modules.map((item, n) => 
                                <Link to={item.link} key={n}><button type="button" className={"btn btn-primary module-btn " + m.cls}>{item.title}</button></Link>
                            )}
                      </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
}
