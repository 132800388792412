import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar, GridRowsProp, GridColDef } from '@mui/x-data-grid';
//import { useDemoData } from '@mui/x-data-grid-generator';
export { Listview };

const VISIBLE_FIELDS = ['name', 'rating', 'country', 'dateCreated', 'isAdmin'];

function Listview(props) {

     // const rows = [
     //    { id: 1, col1: 'Hello', col2: 'World' },
     //    { id: 2, col1: 'DataGridPro', col2: 'is Awesome' },
     //    { id: 3, col1: 'MUI', col2: 'is Amazing' },
     //  ];

     //  const columns = [
     //    { field: 'col1', headerName: 'Column 1', width: 150 },
     //    { field: 'col2', headerName: 'Column 2', width: 150, hide: true },
     //  ];

    const rows = props.rows;
    const columns = props.columns;
    const height = props.height ? props.height : 600;

  return (
    <div style={{height: height, width: '100%'}}>
      {props.hasRowId && <DataGrid rows={rows} columns={columns} getRowId={() => Math.floor(Math.random() * 100000000)} components={{ Toolbar: GridToolbar }}/>}
      {!props.hasRowId && <DataGrid rows={rows} columns={columns} components={{ Toolbar: GridToolbar }}/>}
    </div>
  );
}
