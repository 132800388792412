import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Spinner } from '../components/Spinner'


export default Inventory;

function Inventory() {
    const location = useLocation();
    const navigate = useNavigate();
    //axios = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    const [products, setProducts] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [loading, setLoading] = useState(false);
   
    const onViewButtonClick = (cid, productCode) =>  {
        console.log('ow onViewButtonClick', cid, productCode)

        // companyService.getById(cid).then(x => {

        //     var company_nickname = helperService.toTitleCase(x.COMPANY_NICKNAME);

        //     sessionStorage.setItem('onlinebgc:cid', cid);
        //     sessionStorage.setItem('onlinebgc:cname', company_nickname);

        //     router.push('./products/view/' + productCode)
        // }).catch(alertService.error);
        navigate(`/master/product/${productCode}`);
    }

    async function handleSearch() {
          console.log('search', searchFilter)
          setLoading(true)

          var sFilter = searchFilter ? searchFilter.replace(/ /g,"%") : searchFilter;
          
          const res = await axios.get(`/master/product?q=${sFilter}`);
          const data = res.data;
          setProducts(data)
          
          setLoading(false)
      }

    function handleChange(e) {
       setSearchFilter(e.target.value)
    }

    useEffect(() => {
        // companyService.getAll().then(x => {
        //     setCompanies(x);
        //     handleSearch();
        // });
        
        const fetchData = async () => {
          try {
            const res = await axios.get(`/master/company`);
            //console.log('res', res)
            setCompanies(res.data);
            handleSearch();
          } catch (err) {
            console.log(err);
          }
        };

        fetchData();
    }, []);

    return (
        <div className="container">
            <h1>Inventory</h1>
            <div className="row pt-2">
                <div className="col-md-8">
                    <label className="m-2">Search Product</label>
                    <input id="outlined-search" label="Search Product" type="text" className="m-1" onChange={handleChange} 
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                              handleSearch()
                            }
                        }}
                    />
                    <button type="button" className="btn btn-sm btn-primary mb-1" onClick={handleSearch}>Search</button>
                </div>
            </div>

            <div className="row pt-2">
                <div className="col-md-12">
                    {loading ? <Spinner/> : 
                        <table className="table table-striped table_border" width="100%">
                            <thead>
                                <tr>
                                    <th style={{ width: '30%' }}>Product</th>
                                    <th>Unit</th>
                                    {companies.map(c => 
                                        <th style={{ textAlign:'center' }}>{c.COMPANY_NICKNAME}</th>
                                    )}
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {products && products.map(product =>
                                    <tr key={product.ProductCode}>
                                        <td>{product.Description}</td>
                                        <td>PCS</td>
                                        {companies.map((c,i) =>
                                            <td align="center">
                                                <button
                                                    onClick={(e) => onViewButtonClick(c.CompanyID, product.ProductCode)}
                                                    className="btn btn-sm text-primary"
                                                    variant="contained"
                                                  >
                                                    {Math.floor(Math.random() * (i+25))}
                                                </button>
                                            </td>
                                        )}
                                    </tr>
                                )}
                                {products && !products.length &&
                                    <tr>
                                        <td colSpan="4" className="text-center">
                                            <div className="p-2">No Products To Display</div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        </div>
    );
}
