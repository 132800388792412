export const helper = {
    toTitleCase,
    getSessCompanyId,
    getSessCompanyName,
    numberFormat
};

function toTitleCase(phrase) {
  return phrase && phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

function getSessCompanyId() {

    if (sessionStorage) {
        return sessionStorage.getItem('onlinebgc:cid')
    }

    return null;
}

function getSessCompanyName() {

    if (sessionStorage) {
        return sessionStorage.getItem('onlinebgc:cname')
    } 

    return null;
}

function numberFormat(n, decimal) {
    return Number(parseFloat(n).toFixed(decimal)).toLocaleString('en', { 
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal 
    });
}


