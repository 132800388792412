import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

const Home = () => {
  const [companies, setCompanies] = useState([]);
  //axios = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  //const cat = useLocation().search

  const group = [
            {name: 'Cebu', cls: 'bg-primary', ids: [2,3,4,7,12]},
            {name: 'Cagayan', cls: 'bg-success',ids: [5,6,14,15]},
            {name: 'Davao', cls: 'bg-danger', ids: [9,10,11,13]},
            {name: 'Manila', cls: 'bg-secondary', ids: [8]}
        ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/master/company`);
        console.log('res', res)
        setCompanies(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);
  
  return (
     <div className="container">
        <div className="row justify-content-center">
            <div className="col-md-12">
                {group.map((g,i) => 
                <div className="card mt-3" key={i}>
                    <div className="card-header">
                        <h5>{g.name}</h5>
                    </div>
                    <div className="card-body">
                        {companies.length > 0 && companies.filter(c => g.ids.includes(c.CompanyID)).map((company,n) => 
                            <Link to={"modules?c=" + company.CompanyID} key={n}><button type="button" className={"btn btn-primary company-btn " + g.cls}>{company.COMPANY_NICKNAME}</button></Link>
                        )}
                    </div>
                </div>
                )}
            </div>
        </div>
        </div>
  );
};

export default Home;
