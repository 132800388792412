import axios from "axios";
import React, { useState } from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import Logo from "../img/logo.png";

const Login = () => {
  //axios = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const [err, setError] = useState(null);

  const navigate = useNavigate();

  const { login } = useContext(AuthContext);


  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(inputs)
      navigate("/");
    } catch (err) {
      setError(err.response.data);
    }
  };
  return (
    <div className="containers">
      <div className="row">
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title text-center"><b>Weld Powertools Industrial Machinery Corporation</b></h5>
              <p className="text-center"> <img src={Logo} className="text-align-center" alt=""/></p>
              <p className="card-text"><b>Weld Powertools Industrial Machinery</b> primarily deals with welding electrodes and construction supplies. WPIM deals with wide array of brands to choose from and has proven its valuable contribution in the industrial and construction sectors by providing the market wide array of cost-beneficial products and services. Most of all, WPIM has been recognized as one of the welding expert/specialist in the welding industry.</p>
              <a href="https://weldpowertools.com/" className="btn btn-success text-align-center">View company website</a>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card border-0">
            <div className="card-body">
              <h5 className="card-title"><b>User Login</b></h5>
              <p className="card-text">Please Login to your account.</p>
              <p>
                <form>
                   <div className="form-outline mb-4">
                    <label className="form-label" for="form2Example1"><b>Username</b></label>
                    <input id="form2Example1" className="form-control"  required
                        type="text"
                        placeholder="Type your username"
                        name="username"
                        onChange={handleChange} />
                  </div>

                  <div className="form-outline mb-4">
                    <label className="form-label" for="form2Example2"><b>Password</b></label>
                    <input id="form2Example2" className="form-control"
                        required
                        type="password"
                        placeholder="Type your password"
                        name="password"
                        onChange={handleChange}
                     />
                  
                  </div>

                  <div className="col-md-5">          
                      <button onClick={handleSubmit} className="btn btn-primary">Login</button>
                      {err && <p className="error">{err}</p>}
                      <span>
                        {/*Don't you have an account? <Link to="/register">Register</Link>*/}
                      </span>
                  </div>
                </form>
              </p>
              {/*<a href="#" className="btn btn-primary">Login</a>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
