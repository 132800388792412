import React, { useContext } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
} from "react-router-dom";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Write from "./pages/Write";
import Home from "./pages/Home";
import Single from "./pages/Single";
import Module from "./pages/Module";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import Master from "./pages/Master";
import MasterView from "./pages/MasterView";
import Transaction from "./pages/Transaction";
import TransactionView from "./pages/TransactionView";
import Inventory from "./pages/Inventory";

import { AuthContext } from "./context/authContext";
import "./styles/globals.css"
//import "./styles/module.css"
//import "./styles/style.scss"

const Layout = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <>
      <Navbar />
      {currentUser ?
        <Outlet /> : <Login/>
      }
      <Footer />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/post/:id",
        element: <Single />,
      },
      {
        path: "/write",
        element: <Write />,
      },
      {
        path: "/modules",
        element: <Module />,
      },
      {
        path: "/masterlist/:module",
        element: <Master />,
      },
      {
        path: "/master/:module",
        element: <MasterView />,
      },
      {
        path: "/master/:module/:id",
        element: <MasterView />,
      },
      {
        path: "/transactionlist/:module",
        element: <Transaction />,
      },
      {
        path: "/transaction/:module",
        element: <TransactionView />,
      },
      {
        path: "/transaction/:module/:id",
        element: <TransactionView />,
      },
      {
        path: "/inventory",
        element: <Inventory />,
      }
    ],
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

function App() {
  return (
    <div className="app">
      <div className="app-container">
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
