import React from "react";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import qs from 'query-string'
import pluralize from 'pluralize';

import { helper } from '../helpers'
import { Listview } from '../components/Listview';
import { CompanyLink } from '../components/CompanyLink'
import { Spinner } from '../components/Spinner'

const Master = () => {
  //axios = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const location = useLocation();
  const navigate = useNavigate();

  const moduleName = location.pathname.split("/")[2];
  const moduleApi = pluralize.singular(moduleName);
  const masterKeys = {
    'product' : 'ProductCode',
    'customer' : 'CustomerCode',
    'supplier' : 'SupplierCode',
    'employee' : 'EmployeeCode',
    'chartofaccount' : 'COA_CODE'
  }

  const [records, setRecords] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchFilter, setSearchFilter] = useState('');

  function refreshPage() {
      navigate(`/masterlist/` + pluralize.plural(moduleName))
  }

  const onViewButtonClick = (e, row) =>  {
      console.log('ow onViewButtonClick', row)
      navigate(`/master/${moduleApi}/${row[masterKeys[moduleApi]]}`);
  }

  async function handleSearch() {
      console.log('search', searchFilter)
      setLoading(true)

      var sFilter = searchFilter ? searchFilter.replace(/ /g,"%") : searchFilter;
      
      const res = await axios.get(`/master/${moduleApi}?q=${sFilter}`);
      const data = res.data;
      setRecords(data)
      
      setLoading(false)
  }

  function handleChange(e) {
     setSearchFilter(e.target.value)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {

        setLoading(true);

        const res = await axios.get(`/master/${moduleApi}`);
        const x = res.data;
        setRecords(x);
        var line1 = x.length > 0 && x[0];

        //get columns
        var cols = Object.keys(line1).map((y, i) => {

            var hidden = false;

            if (i > 12) hidden = true;

            if (['Description', 'CustomerName', 'SupplierName', 'LastName', 'COA_DESCRIPTION', 'FullName'].includes(y)) {
                return {field: y, headerName: y, width: 300, hide: hidden}
            }

            return {field: y, headerName: y, hide: hidden}
        });

        cols.unshift({ field: 'actions', headerName: 'Actions', width: 80, filterable: false, renderCell: (params) => {
            return (
              <button
                onClick={(e) => onViewButtonClick(e, params.row)}
                className="btn btn-sm btn-secondary"
                variant="contained"
              >
                view
              </button>
            );
          }})

        setColumns(cols);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [moduleName]);
 

  return (
    <div className="container">
        <CompanyLink/>
        <h3>{helper.toTitleCase(moduleName)}</h3>
        <div className="row pt-2">
            <div className="col-md-8">
                <label className="m-2">Quick Search</label>
                <input id="outlined-search" label="Search Product" type="text" className="m-1" onChange={handleChange} 
                    onKeyPress={event => {
                        if (event.key === 'Enter') {
                          handleSearch()
                        }
                    }}
                />
                <button type="button" className="btn btn-sm btn-primary mb-1" onClick={handleSearch}>Search</button>
            </div>
            <div className="col-md-4">
                {/*<Link href="/products/add" className="btn btn-sm btn-primary">Add</Link>*/}
                <button type="button" className="btn btn-sm btn-primary margin-btn float-end" onClick={refreshPage}>Refresh</button>
                <Link to={"/modules?c=" + helper.getSessCompanyId()} className="btn btn-sm btn-primary margin-btn float-end">Back</Link>
                <Link to={"/master/" + moduleName + '?action=new'} className="btn btn-sm btn-primary margin-btn float-end">New</Link>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>{loading ? <Spinner/> : <Listview rows={records} columns={columns} hasRowId={true} />}</p>
            </div>
        </div>
    </div>
  );
};

export default Master;
