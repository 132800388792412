import { useState, useEffect } from 'react';
import { DataGrid, GridToolbar, GridRowsProp, GridColDef } from '@mui/x-data-grid';
//import { customerService, alertService, helperService } from 'services';
import qs from 'query-string';
import moment from 'moment';
import { helper } from '../helpers'

export { ViewTabLedger };

function ViewTabLedger(props) {

  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);

     // const rows = [
     //    { id: 1, col1: 'Hello', col2: 'World' },
     //    { id: 2, col1: 'DataGridPro', col2: 'is Awesome' },
     //    { id: 3, col1: 'MUI', col2: 'is Amazing' },
     //  ];

      const columns = [
        { field: 'TransDate', headerName: 'Code', width:100, 
            valueFormatter: params => moment(params?.value).format("YYYY-MM-DD")},
        { field: 'TransType', headerName: 'Description', width:200},
        { field: 'TransNo', headerName: 'Quantity', width:250},
        { field: 'RefNo', headerName: 'Unit', width:250},
        { field: 'UnitPrice', headerName: 'Unit Price', width:150, align: 'right', valueFormatter: params => helper.numberFormat(params?.value, 2)},
        { field: 'UnitPrice', headerName: 'Discount', width:150, align: 'right', valueFormatter: params => helper.numberFormat(params?.value, 2)},
        { field: 'Amount', headerName: 'Amount', width:150, align: 'right', valueFormatter: params => helper.numberFormat(params?.value, 2)}
      ];

      const height = 300;


  useEffect(()=> {
    // const value=qs.parse(document.location.search);
    // //const cid = value.c;
    // console.log('value', value)
     
      // customerService.getReceivable(helperService.getSessCompanyId(), props.CustomerCode)
      //       .then(x => {
      //         setRows(x);

      //         var amount = x.reduce((a, b) => a + parseFloat(b.Amount), 0);
      //         amount = helperService.numberFormat(amount, 2);

      //         setTotal(amount);
      //       })
      //       .catch(alertService.error)
  }, [])

  return (
    <div style={{height: height, width: '100%'}}>
      <DataGrid rows={rows} columns={columns} getRowId={() => Math.floor(Math.random() * 100000000)}/>
    </div>
  );
}
