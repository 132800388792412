import { useState, useEffect } from 'react';

import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import qs from 'query-string'
import pluralize from 'pluralize';

import { helper } from '../helpers'
import { Listview } from '../components/Listview';
import { CompanyLink } from '../components/CompanyLink'
import { Spinner } from '../components/Spinner'
import { ViewTab } from '../components/ViewTab'

export default ViewRecord;

function ViewRecord() {
    //axios = axios.create({ baseURL: process.env.REACT_APP_API_URL });
    const [record, setRecord] = useState({});
    const [columns, setColumns] = useState([]);
    const [config, setConfig] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [error, setError] = useState(null);
   
    const location = useLocation();
    const navigate = useNavigate();
   
    const action = searchParams.get('action');

    console.log('action1',action)

    const moduleName = location.pathname.split("/")[2];
    const moduleApi = pluralize.singular(moduleName);
    const moduleId = location.pathname.split("/")[3];
    const masterLabels = {
      'product' : 'Description',
      'customer' : 'CustomerName',
      'supplier' : 'SupplierName',
      'employee' : 'LastName',
      'chartofaccount' : 'COA_DESCRIPTION'
    };

   const skipFields = ['createdon', 'lastuseraccessed', 'timestampheader', 'replicationdatetime'];

    const handleChange = ({ target }) => {
        const newRecord = { ...record };
        
        const { name, value } = target;

        if (target.type && target.type == 'checkbox') {
            newRecord[name] = target.checked ? true : false;
        } else {
            newRecord[name] = value;    
        }

        setRecord(newRecord);
        console.log('new', newRecord)
    };

    const handleSave = async (e) => {
        console.log('handleSave');
        e.preventDefault();
        try {

            //set defaults
            var keys = Object.keys(record);
            for(var k of keys) {
                if (skipFields.includes(k.toLowerCase())) {
                    delete record[k];
                }

                if (!moduleId && k.toLowerCase() == 'companyid') record[k] = helper.getSessCompanyId();

                if (!moduleId && k.toLowerCase() == 'transactionid') record[k] = 0;
            }

            if (moduleId) {
                const post = {
                    table: config.header.table,
                    pk: config.header.pk,
                    values: record
                };
                console.log('post', post)
                await axios.put(`/master/${moduleApi}/${moduleId}`, post);  
                navigate(`/master/${moduleApi}/${moduleId}`);  
            } else {

                //record[config.header.pk] = 1;

                const post = {
                    table: config.header.table,
                    pk: config.header.pk,
                    values: record
                };

                console.log('post', post)
                await axios.post(`/master/${moduleApi}`, post); 
                navigate("/masterlist/" + pluralize.plural(moduleName));  
            }
        } catch (err) {
            console.log('error', err);
            var errMem = (err.response && err.response.data) ? err.response.data : err;
            
            if (errMem.sqlMessage) 
                setError({code: errMem.code, message: errMem.sqlMessage})
            else 
                setError(errMem)
            
        }
      };
    
    useEffect(() => {
      const fetchData = async () => {
        try {

          const res = await axios.get(`/master/${moduleApi}/${moduleId}`);
          console.log('res', res)
          const data = res.data;
          setRecord(data);

          //config
          const res2 = await axios.get(`/config/${moduleApi}`);
          const resConfig = res2.data;
          setConfig(resConfig);
          
          //columns
          const res3 = await axios.get(`/config/columns/${resConfig.header.table}`);
          console.log('res3', res3)

          const cols = await res3.data.filter((y, i) => {

              var hidden = false;
              var fieldName = y.Field;

              //if (i > 12) hidden = true;

              const hiddenFields = ['userpass'];
              const readonlyFields = ['createdby', 'createdon', 'lastuseraccessed', 'lasttimeaccessed', 
                        'companyid', 'sitecode', 'transactionid', 'timestampheader', 'replicationdatetime'];

              if (hiddenFields.includes(fieldName.toLowerCase())) {
                    return false;
                    //return {name: fieldName, label: fieldName, readonly: false, type: 'text', hide: true}
              }

              if (readonlyFields.includes(fieldName.toLowerCase())) {

                return false;
                //return {name: fieldName, label: fieldName, readonly: true, disabled:true, type: 'text', hide: true}
              }

              if (y.Key && action=='new') {
                //return false;
              }

              return true;
              //return {name: fieldName, label: fieldName, readonly: false, type: 'text', hide: hidden}
          }).map((y, i) =>{

            const fieldName = y.Field;
            var disabled = false;

            console.log('action', action, resConfig.header.pk)
            if (resConfig.header.pk == fieldName && action != 'new') {
                disabled = true;
            }

            return {name: fieldName, label: fieldName, disabled: disabled, type: 'text', hide: false}
          });

          setColumns(cols);

        } catch (err) {
            console.log(err);
        }
      };
      fetchData();
    }, [moduleApi]);

    return (
        <div className="container">
            <CompanyLink/>
            <h4><b>{record[masterLabels[moduleApi]]}</b></h4>
            <div className="form-group">
                {(action != 'edit' && action != 'new') && <Link to={"/masterlist/" + pluralize.plural(moduleName)} className="btn btn-primary margin-btn">Back</Link>}
                {(action != 'edit' && action != 'new') && <Link to={`/master/${moduleApi}/${moduleId}?action=edit`} className="btn btn-primary margin-btn">Edit</Link>}
                {(action == 'edit' || action == 'new') && <button className="btn btn-primary margin-btn" onClick={handleSave}>Save</button>}
                {(action == 'edit')  && <Link to={`/master/${moduleApi}/${moduleId}`} className="btn btn-primary margin-btn">Cancel</Link>}
                {(action == 'new')  && <Link to={"/masterlist/" + pluralize.plural(moduleName)} className="btn btn-primary margin-btn">Cancel</Link>}
            </div>
            {error && <p className="error">{JSON.stringify(error)}</p>}
            <div className="row">
                {columns.map((c,i) => 
                    <div className="col-md-3 mt-2" key={i}>
                        <h5 className="text-uppercase font-weight-bold">{c.label ? c.label : c.name}</h5>
                        {!action 
                            ? (<p className="text-primary">{record[c.name] ? record[c.name] : null}</p>)
                            : (<input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="" 
                                    aria-label={c.label ? c.label : c.name} 
                                    aria-describedby={c.name} 
                                    value={record[c.name] ? record[c.name] : null} 
                                    disabled={c.disabled ? true : false}
                                    name={c.name}
                                    onChange={handleChange}
                                />)
                        }
                    </div>
                )}
            </div>
            <div className="row">
                <div className="col-md-12">
                    <ViewTab config={config}/>
                </div>
            </div>

            <div className="form-group">
                <Link to={"/masterlist/" + pluralize.plural(moduleName)} className="btn btn-primary mt-2">Back</Link>
            </div>
        </div>
    );
}

export async function getServerSideProps({ params }) {
    return {
        props: { id: params.id }
    }
}
