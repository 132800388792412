import React, { useContext } from "react";
import Logo from "../img/logo.png";
import { AuthContext } from "../context/authContext";

const Footer = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    // <footer className="footer">
    //   <div className="row">
    //     <div className="col-md-10">
    //       <img src={Logo} alt="" height="35" /> Weld Powertools Industrial Machinery Corporation
    //     </div>
    //     <div className="col-md-2 text-right p-2">
    //        <em>Currently Logged In: {currentUser?.username}</em>
    //     </div>
    //   </div>
    // </footer>
    <></>
  );
};

export default Footer;
